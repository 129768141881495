const getParameterByName = paramName => {
  paramName = paramName.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regexS = '[\\?&]' + paramName + '=([^&#]*)';
  var regex = new RegExp(regexS);
  var results = regex.exec(window.location.href);
  if (results == null) return '';
  else return decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const removeParam = paramName => {
  let searchParams = new URLSearchParams(window.location.search);
  searchParams.delete(paramName);
  if (history.replaceState) {
    let searchString =
      searchParams.toString().length > 0 ? '?' + searchParams.toString() : '';
    let newUrl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      searchString +
      window.location.hash;
    history.replaceState(null, '', newUrl);
  }
};

const appendStoredBirthDateIfPresent = () => {
  const dob = new URLSearchParams(window.location.search).get('dob');
  if (dob) {
    window.localStorage.setItem('dob', dob);
  }
  return window.localStorage.getItem('dob');
};

const appendPatientNameIfPresent = () => {
  const patientName = new URLSearchParams(window.location.search).get(
    'patientName'
  );
  if (patientName) {
    window.localStorage.setItem('patientName', patientName);
  }
  return window.localStorage.getItem('patientName');
};

const appendStoredUidsIfPresent = () => {
  const uids = new URLSearchParams(window.location.search).get('uids');
  if (uids) {
    localStorage.setItem('StudyInstanceUID', uids);
  }
  return localStorage.getItem('StudyInstanceUID');
};

export {
  getParameterByName,
  removeParam,
  appendStoredBirthDateIfPresent,
  appendStoredUidsIfPresent,
  appendPatientNameIfPresent,
};
